import React from 'react';
import {connect} from "react-redux";
import CompanyDetails from "./CompanyDetails";
import ContentBox from "../../common/ContentBox";

function Landing(props) {

    return (
        <ContentBox match={props.match}>
            <CompanyDetails match={props.match}/>
        </ContentBox>
    )
}
const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps, null)(Landing)
