import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import Routing from "./navigation/router";
import {store, persistent_store, history} from "./store/config/configureStore";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
      <I18nextProvider i18n={i18n}>
          <Provider store={store}>
              <ConnectedRouter history={history}>
                  <PersistGate persistor={persistent_store}>
                        <Routing/>
                  </PersistGate>
              </ConnectedRouter>
          </Provider>
      </I18nextProvider>
  );
}

export default App;
