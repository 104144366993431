const influencer = (state = {}, action) => {
    switch (action.type) {
        case 'ONBOARDING_DATA_FETCH_SUCCESS':
            return {
                isLoading: false,
                hasErrored: false,
                auth_token: action.authToken,
            };
        case 'ONBOARDING_DATA_IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
                hasErrored: false};

        case 'ONBOARDING_DATA_HAS_ERRORED':
            return {
                hasErrored: action.hasErrored,
                isLoading: false,
                errorDetails: action.errorDetails
            };
        case 'ONBOARDING_REMOVE_DATA':
            return {};
        default:
            return state
    }
};


export default influencer
