import {getCompanyDetails} from "../actions/company";
import {getFAQList} from "../actions/faq";

export function forceBackendRefetchAfterLanguageSwitch() {
    /**
     * we need to re-fetch the backend as translations come from the frontend AND backend
     */
    return (dispatch, getState) => {
        if (getState().company.name) {
            dispatch(getCompanyDetails(getState().company.name))
        }
        if(getState().faq.list) {
            dispatch(getFAQList())
        }
    }
}