import React, {useState} from "react";
import {Dropdown} from "react-bootstrap";
import i18n from "../i18n"
import {forceBackendRefetchAfterLanguageSwitch} from "../store/common/language_switch";
import {connect} from "react-redux";

function ContentBox(props) {
    const [language, setLanguage] = useState('en-EN');
    
    if (i18n.language === 'de-DE' && language !== 'de-DE') {
      setLanguage('de-DE')
    }
    function switchLanguage(language_code) {
        setLanguage(language_code);
        i18n.changeLanguage(language_code);
        props.forceCompanyFetch()
    }
    return(
    <div>
        <Dropdown style={{"textAlign": "right"}} variant="sm">
            <Dropdown.Toggle className="btn btn-dark emoji" variant="sm" style={{margin: 5}}>
                <img src={require(`../assets/images/language/${language}.png`)} alt="Language" width="20" style={{marginRight: 12, marginLeft: 6}}/> Languages
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
                <Dropdown.Item onClick={()=> switchLanguage("de-DE")}>German</Dropdown.Item>
                <Dropdown.Item onClick={()=> switchLanguage("en-EN")}>English</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <div className="row">
            <div className="col-lg-4 offset-lg-4  col-sm-10 offset-sm-1 mt-5 text-container p-5 shadow rounded message bg-white"
                 style={{...{minHeight: 300}, ...props.style}}>
                    {props.children}
            </div>
        </div>
    </div>
    )
}
const mapStateToProps = (state) => {
    return state
};
const mapDispatchToProps = (dispatch) => ({
    forceCompanyFetch: () => dispatch(forceBackendRefetchAfterLanguageSwitch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentBox)
