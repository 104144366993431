import rootReducer from '../reducers';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {persistReducer, persistStore} from 'redux-persist';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';


/**
 * This is the default config for the configureStore
 **/

export const history = createBrowserHistory();

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk, routerMiddleware(history)),
);

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ["influencer", "router", "faq"]
};

const persist_reducer = persistReducer(persistConfig, rootReducer(history));
export const store = createStore(persist_reducer, enhancer);
export const persistent_store = persistStore(store);
