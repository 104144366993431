import React from 'react';
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import Landing from "../pages/Landing/Landing";
import Dashboard from "../pages/Success/Dashboard";
import FAQOverview from "../pages/FAQ/FAQOverview";

function Routing(props) {

    return (
        <Switch>
            <Route exact path="/" {...(props.influencer.auth_token && !props.company.isLoading && !props.company.hasErrored ?
                {render: ()=>(<Redirect to={"/success"}/>)}
                : {component: Landing})}
            />
            <Route exact path="/success" component={Dashboard}/>
            <Route exact path="/faq" component={FAQOverview}/>
            <Route exact path="/social_match/" render={() => <Redirect to={"/register"}/>}/>
            <Route exact path="/reporting/" render={() => window.location.href = process.env.REACT_APP_REPORTING_URL}/>
            <Route path="/:company_slug/" component={Landing} />
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps)(Routing);