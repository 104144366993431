import React, { useState } from "react";
import {FacebookProvider, Login} from "react-facebook";
import {doOnboarding} from "../../store/actions/influencer";
import {connect} from "react-redux";
import "../../styles/pages/Landing/InfluencerDialogue.css";
import {LoadingSpinner} from "../../common/LoadingSpinner";
import {Redirect} from "react-router";
import ErrorDetails from "../../common/ErrorDetails";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

function InfluencerDialogue(props) {

    function handleResponse(response){
        props.doOnboarding(response.tokenDetail.accessToken, username, props.company.name);
    }

    const queryString = require('query-string');
    const url_params = queryString.parse(props.router.location.search);

    const [username, setUsername] = useState(url_params.influencer ? url_params.influencer : "");

    function sufficient_input() {
        return username.length >= 3;
    }

    if(props.influencer.auth_token) {
        /**
         * redirect after successful onboarding
         */
        return <Redirect to={"/success"}/>
    }

    if (props.influencer.isLoading) {
        return <LoadingSpinner/>
    }
    else {
        return (
            <div>
                {props.influencer.hasErrored ?
                    <ErrorDetails error={props.influencer.errorDetails}/>
                    : ""
                }
                <div className="form-group InfluencerDialogue">
                    <label htmlFor="username">
                        {props.t("enterInstagramUsername")}
                    </label>
                    <input type="email" className="form-control" id="username"
                           placeholder={props.t("Instagram Username")}
                           value={username}
                           onChange={event => setUsername(event.target.value)}/>
                </div>
                <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID} cookie={true} fbxml={true}
                                  version={process.env.REACT_APP_FACEBOOK_API_VERSION}>
                    <Login
                        scope="ads_management,business_management,manage_pages,instagram_basic,instagram_manage_insights"
                        onCompleted={handleResponse}
                        //onError={handleError}
                    >
                        {({loading, handleClick, error, data}) => (
                            <button className="loginBtn loginBtn--facebook" onClick={handleClick} disabled={!sufficient_input()}>
                                {props.t("continueWithFacebook")}
                            </button>
                        )}
                    </Login>
                </FacebookProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = (dispatch) => ({
    doOnboarding: (fb_access_token, instagram_username, company_slug) => dispatch(doOnboarding(fb_access_token, instagram_username, company_slug)),
});

export default compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps))(InfluencerDialogue)