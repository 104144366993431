import axios from "axios";
import i18n from "../../i18n";

export function dataHasErrored(bool, error_details) {
    return {
        type: 'ONBOARDING_DATA_HAS_ERRORED',
        hasErrored: bool,
        errorDetails: error_details
    };
}

export function dataIsLoading(bool) {
    return {
        type: 'ONBOARDING_DATA_IS_LOADING',
        isLoading: bool
    };
}

export function dataFetchSuccess(data) {
    return {
        type: 'ONBOARDING_DATA_FETCH_SUCCESS',
        authToken: data.auth_token
    };
}


const baseURL = process.env.REACT_APP_BACKEND_URL;


export function doOnboarding (fb_access_token, instagram_username, company_slug) {
    return dispatch => {
        dispatch(dataIsLoading(true));
        const endpoint = `${baseURL}/rest/v0.1/validation/`;

        const influencer_fetch = axios.create();
        influencer_fetch.defaults.headers["common"] = {"accept-language": i18n.language};

        influencer_fetch.post(endpoint, {
                facebook_access_token: fb_access_token,
                instagram_username,
                company: company_slug
            })
            .then(function (response) {
                dispatch(dataFetchSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(dataHasErrored(true, error.response.data))
            });
    }
}

export function removeInfluencerCredentials() {
    return {
        type: "ONBOARDING_REMOVE_DATA"
    }
}
