import React from "react";
import ReactLoading from 'react-loading';

export function LoadingSpinner(props) {

    let default_styles = {
        margin: "0 auto",
        color: "black",
        height: 60,
        width: 60
    };

    return (
        <div>
            <ReactLoading type={"spin"} style={{...props.style, ...default_styles}} />
        </div>
    )
}