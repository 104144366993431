import React, { Component } from "react";
import {connect} from "react-redux";
import {getCompanyDetails} from "../../store/actions/company";
import {LoadingSpinner} from "../../common/LoadingSpinner";
import InfluencerDialogue from "./InfluencerDialogue";
import "../../styles/pages/Landing/CompanyDetails.css";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
// import {
//   GoogleReCaptcha
// } from 'react-google-recaptcha-v3';

class CompanyDetails extends Component{

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            recaptchaVerified: false,
        }
    }

    componentDidMount() {
        if(this.props.company.name) {
            if(this.props.match.params.company_slug !== undefined &&
                this.props.match.params.company_slug !== this.props.company.name) {
                this.props.companyDetails(this.props.match.params.company_slug);
            }
        }
        else {
            if (window.location.href.includes("share.kartenmacherei")) {
                this.props.companyDetails("kartenmacherei")
            } else if (this.props.match.params.company_slug === undefined) {
                this.props.companyDetails("register");
            } else {
                this.props.companyDetails(this.props.match.params.company_slug);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.company) {
            //Change the favicon
            let link = document.querySelector('link[rel="shortcut icon"]') ||
                document.querySelector('link[rel="icon"]');

            if (!link) {
                link = document.createElement('link');
                link.id = 'favicon';
                link.rel = 'shortcut icon';
                document.head.appendChild(link);
            }
            if(this.props.company.logo) {
                link.href = this.props.company.logo
            }

            //Change the title
            if(this.props.company.full_name) {
                document.title = `${this.props.company.full_name} | Influencer`;
            }
        }
    }

    render() {
        if (this.props.company.isLoading) {
            return <LoadingSpinner/>
        } else if (this.props.company.page_description) {
            return (
                <div>
                    <img src={this.props.company.logo} alt={this.props.company.full_name} className="company-logo"/>
                    {this.state.step === 1 ?
                    <div>
                        <div dangerouslySetInnerHTML={{__html: this.props.company.page_description}}></div>
                      {/*{this.state.recaptchaVerified === false ? (*/}
                      {/*  <GoogleReCaptcha onVerify={token => this.setState({recaptchaVerified: true})} />*/}
                      {/*  ) : (*/}
                          <button type="button" className="btn btn-dark" onClick={() => this.setState({step: 2})}>
                            {this.props.t("Continue")}
                          </button>
                      {/*)*/}
                      {/*}*/}
                    </div>
                    : <InfluencerDialogue/>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    The page you are looking for does not exist. Please try to refresh the page if you believe this is
                    an error.
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = (dispatch) => ({
    companyDetails: (company_slug) => dispatch(getCompanyDetails(company_slug)),
});

export default compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps))(CompanyDetails)
