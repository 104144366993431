const faq = (state = {}, action) => {
    switch (action.type) {
        case 'FAQ_DATA_FETCH_SUCCESS':
            return {list: action.data};
        case 'FAQ_DATA_IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
                hasErrored: false};

        case 'FAQ_DATA_HAS_ERRORED':
            return {
                hasErrored: action.hasErrored,
                isLoading: false,
                errorDetails: action.errorDetails
            };
        default:
            return state
    }
};


export default faq