import React from "react";
import {Accordion, Card} from "react-bootstrap";
import {withTranslation} from "react-i18next";

function ErrorDetails(props) {
    return (
        <div className="rounded">
            <Accordion defaultActiveKey="0">
                <Card className="rounded">
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <h5>{props.t("errorOccurred")}:</h5>
                        <h6>{ props.error.message ? props.error.message : "Unknown error"}</h6>
                        {props.error.error_message_html && props.error.error_message_html.length > 4 ?
                            <i>{props.t("tapForMoreDetails")}</i>
                            : ""
                        }
                    </Accordion.Toggle>
                    { props.error.error_message_html && props.error.error_message_html.length > 4 ?
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <span dangerouslySetInnerHTML={{__html: props.error.error_message_html}}/>
                        </Card.Body>
                    </Accordion.Collapse>
                    : ""}
                </Card>
            </Accordion>
        </div>
    )
}
export default withTranslation()(ErrorDetails)