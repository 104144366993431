import { combineReducers } from 'redux'
import influencer from "./influencer";
import company from "./company";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router'
import faq from "./faq";

const influencerPersistConfig = {
    key: 'influencer',
    storage: storage,
    blacklist: ['errorDetails', "hasErrored", "isLoading"]
};

export default (history) => combineReducers({
    influencer: persistReducer(influencerPersistConfig, influencer),
    company,
    faq,
    router: connectRouter(history),
})

// Standard reducer without persist
//export default combineReducers({
//    influencer,
//    company,
//})
