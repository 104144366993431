const company = (state = {}, action) => {
    switch (action.type) {
        case 'COMPANY_DATA_FETCH_SUCCESS':
            return action.data;
        case 'COMPANY_DATA_IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
                hasErrored: false};

        case 'COMPANY_DATA_HAS_ERRORED':
            return {
                hasErrored: action.hasErrored,
                isLoading: false,
                errorDetails: action.errorDetails
            };
        default:
            return state
    }
};


export default company