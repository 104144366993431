import React from 'react';
import {connect} from "react-redux";
import ContentBox from "../../common/ContentBox";
import {Redirect} from "react-router";
import {removeInfluencerCredentials} from "../../store/actions/influencer";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function Dashboard(props) {

    if(!props.company.name || !props.influencer.auth_token) {
        return (
            <Redirect to={"/"}/>
        )
    }

    return (
        <div>
            <ContentBox>
                <span dangerouslySetInnerHTML={{__html: props.company.page_success}}/>
                <button type="button" className="btn btn-dark" onClick={() => props.logOut()}>
                    {props.t("dashboardLogOutButton")}
                </button>
            </ContentBox>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch(removeInfluencerCredentials())
});

export default compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps))(Dashboard)