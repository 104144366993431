import axios from "axios";
import i18n from "../../i18n"

function dataHasErrored(bool, error_details) {
    return {
        type: 'FAQ_DATA_HAS_ERRORED',
        hasErrored: bool,
        errorDetails: error_details
    };
}

function dataIsLoading(bool) {
    return {
        type: 'FAQ_DATA_IS_LOADING',
        isLoading: bool
    };
}

function dataFetchSuccess(data) {
    return {
        type: 'FAQ_DATA_FETCH_SUCCESS',
        data: data
    };
}

const baseURL = process.env.REACT_APP_BACKEND_URL;


export function getFAQList() {
    return dispatch => {
        dispatch(dataIsLoading(true));

        const endpoint = `${baseURL}/rest/v0.1/faq/`;

        const faq_fetch = axios.create();
        faq_fetch.defaults.headers["common"] = {"accept-language": i18n.language};

        faq_fetch.get(endpoint)
            .then(function (response) {
                dispatch(dataFetchSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(dataHasErrored(true, error))
            });
    }
}