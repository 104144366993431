import axios from "axios";
import i18n from "../../i18n"

export function dataHasErrored(bool, error_details) {
    return {
        type: 'COMPANY_DATA_HAS_ERRORED',
        hasErrored: bool,
        errorDetails: error_details
    };
}

export function dataIsLoading(bool) {
    return {
        type: 'COMPANY_DATA_IS_LOADING',
        isLoading: bool
    };
}

export function dataFetchSuccess(data) {
    return {
        type: 'COMPANY_DATA_FETCH_SUCCESS',
        data: data
    };
}


const baseURL = process.env.REACT_APP_BACKEND_URL;


export function getCompanyDetails(company_slug) {
    return dispatch => {
        dispatch(dataIsLoading(true));

        const endpoint = `${baseURL}/rest/v0.1/company/${company_slug}/`;

        const company_fetch = axios.create();
        company_fetch.defaults.headers["common"] = {"accept-language": i18n.language};

        company_fetch.get(endpoint)
            .then(function (response) {
                dispatch(dataFetchSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(dataHasErrored(true, error))
            });
    }
}

