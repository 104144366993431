import React from 'react';
import {connect} from "react-redux";
import ContentBox from "../../common/ContentBox";
import {getFAQList} from "../../store/actions/faq";
import {LoadingSpinner} from "../../common/LoadingSpinner";
import {Accordion, Button, Card} from "react-bootstrap";
import ReactMarkdown from "react-markdown";

class FAQOverview extends React.Component {

    componentDidMount(){
        this.props.getFAQ()
    }

    render() {
        if(this.props.faq.isLoading){
            return (
                <ContentBox>
                    <LoadingSpinner/>
                </ContentBox>
            )
        }
        console.log(this.props.faq.list)
        return (
            <ContentBox match={this.props.match}>
                <h1>FAQ</h1>
                {this.props.faq.list ?
                <Accordion style={{marginTop: 20}}>
                    {this.props.faq.list.map((faq_article, index) =>
                    <Card key={index}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" style={{color: "black"}} eventKey={index}>
                                {faq_article.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                                <ReactMarkdown source={faq_article.description_markdown} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    )}
                </Accordion>
                    : "" }
            </ContentBox>
        )
    }
}
const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = (dispatch) => ({
    getFAQ: () => dispatch(getFAQList())
});
export default connect(mapStateToProps, mapDispatchToProps)(FAQOverview)